import React from 'react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'

import { FormControlWrapper } from '../FormControlWrapper/FormControlWrapper'

type InputProps = React.ComponentProps<typeof Input>

type Props = InputProps & {
  label: string
  name: string
  required?: boolean
}

export const PasswordInputWithLabel: React.FC<Props> = (props) => {
  const { label, name, required, type, ...p } = props
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  return (
    <FormControlWrapper label={label} name={name} required={required}>
      <InputGroup size="md">
        <Input {...p} id={name} name={name} type={show ? 'text' : 'password'} />
        <InputRightElement width="4.5rem">
          {show ? (
            <ViewOffIcon cursor={'pointer'} onClick={handleClick} />
          ) : (
            <ViewIcon cursor={'pointer'} onClick={handleClick} />
          )}
        </InputRightElement>
      </InputGroup>
    </FormControlWrapper>
  )
}
